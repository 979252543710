@media (prefers-reduced-motion: no-preference) {
  
}

body{
  margin: 0px
}

.App{
  
}
textarea {
  width: 100%;
  height: 40vh;
  
  
  box-sizing: border-box;
  border: 2px solid #ccf;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none
}

.Result {
  height: 50vh;
}

.button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #28a745;
  color: white;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.button:hover {
  background-color: #218838;
}


.Footer {
  background-color: lightgray;
  text-align: center;
  
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.tabs {
  display: flex;
  gap: 10px;
}

.tab {
  padding: 10px 20px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  border-bottom: 2px solid transparent;
}

.tab.active {
  border-bottom: 2px solid #4CAF50;
  color: #4CAF50;
}

.tab:hover {
  border-bottom: 2px solid #4CAF50;
  color: #4CAF50;
}

.table-container {
  margin: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.data-table th,
.data-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.data-table th:first-child,
.data-table td:first-child {
  width: 400px;
}

.data-table th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.data-table input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.data-table input:focus {
  outline: none;
  border-color: #4CAF50;
}

.delete-button {
  padding: 6px 12px;
  background-color: #ff4444;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.delete-button:hover {
  background-color: #cc0000;
}

.period-input-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: fit-content;
}

.period-select {
  width: 200px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
  cursor: pointer;
  flex-shrink: 0;
}

.period-select:focus {
  outline: none;
  border-color: #4CAF50;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
}

.period-select option {
  padding: 8px;
}

.date-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.date-input:focus {
  outline: none;
  border-color: #4CAF50;
}

.date-input.disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
  color: #999;
}

.date-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: white;
  cursor: pointer;
}

.date-select:focus {
  outline: none;
  border-color: #4CAF50;
}

.date-select option {
  padding: 8px;
}

.date-grid-container {
  width: 100%;
  padding: 10px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.date-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
  width: 100%;
}

.date-grid-item {
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: none;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
  transition: all 0.2s;
}

.date-grid-item:hover {
  background-color: #f0f0f0;
  border-color: #4CAF50;
}

.date-grid-item.selected {
  background-color: #4CAF50;
  color: white;
  border-color: #4CAF50;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 90%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.modal-close {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #666;
}

.modal-close:hover {
  color: #000;
}

.date-input-container {
  display: flex;
  gap: 8px;
  align-items: center;
  width: fit-content;
}

.select-date-button {
  padding: 8px 12px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
  transition: background-color 0.3s;
}

.select-date-button:hover {
  background-color: #45a049;
}

.weekday-selector {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  padding: 4px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.weekday-button {
  flex: 1;
  min-width: 40px;
  padding: 4px 8px;
  border: 1px solid #ddd;
  background-color: #fff;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
  transition: all 0.2s ease;
}

.weekday-button[data-day="суббота"],
.weekday-button[data-day="воскресенье"] {
  background-color: #fff5f5;
}

.weekday-button:hover {
  background-color: #f0f0f0;
}

.weekday-button[data-day="суббота"]:hover,
.weekday-button[data-day="воскресенье"]:hover {
  background-color: #ffe5e5;
}

.weekday-button.selected {
  background-color: #4CAF50;
  color: white;
  border-color: #4CAF50;
}

.weekday-button[data-day="суббота"].selected,
.weekday-button[data-day="воскресенье"].selected {
  background-color: #4CAF50;
  color: white;
  border-color: #4CAF50;
}

.amount-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  text-align: right;
}

.amount-input:focus {
  outline: none;
  border-color: #4CAF50;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
}

.amount-input::placeholder {
  color: #999;
}

.description-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.description-input:focus {
  outline: none;
  border-color: #4CAF50;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
}

.description-input::placeholder {
  color: #999;
}

.checkbox-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.inflation-checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
  accent-color: #4CAF50;
}

.inflation-checkbox:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
}

.table-settings {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.settings-group {
  display: flex;
  gap: 20px;
  align-items: center;
}

.setting-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.setting-item label {
  font-size: 14px;
  color: #333;
  white-space: nowrap;
}

.settings-input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 120px;
}

.settings-input:focus {
  outline: none;
  border-color: #4CAF50;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
}

.settings-unit {
  font-size: 14px;
  color: #666;
}

.calendar {
  padding: 20px;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

.calendar-title {
  flex: 1;
  margin: 0 10px;
}

.calendar-nav-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #666;
  padding: 5px 10px;
  border-radius: 4px;
  transition: all 0.2s;
}

.calendar-nav-button:hover {
  background-color: #f0f0f0;
  color: #333;
}

.calendar-nav-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
}

.calendar-weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  margin-bottom: 10px;
}

.calendar-weekday {
  text-align: center;
  font-weight: bold;
  color: #666;
  padding: 5px;
}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.calendar-day {
  aspect-ratio: 1;
  border: 1px solid #ddd;
  background: none;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: all 0.2s;
}

.calendar-day:not(:disabled):hover {
  background-color: #f0f0f0;
}

.calendar-day.selected {
  background-color: #4CAF50;
  color: white;
  border-color: #4CAF50;
}

.calendar-day.empty {
  border: none;
  cursor: default;
}

.calendar-day:disabled {
  cursor: default;
  opacity: 0.5;
}

.month-year-button {
  background: none;
  border: none;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 4px;
  transition: all 0.2s;
}

.month-year-button:hover {
  background-color: #f0f0f0;
}

.month-select {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.month-option {
  padding: 8px;
  border: 1px solid #ddd;
  background: none;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  border-radius: 4px;
  transition: all 0.2s;
}

.month-option:hover {
  background-color: #f0f0f0;
}

.month-option.selected {
  background-color: #4CAF50;
  color: white;
  border-color: #4CAF50;
}

.year-input {
  width: 80px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.year-input:focus {
  outline: none;
  border-color: #4CAF50;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
}

.year-input::-webkit-inner-spin-button,
.year-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.error-message {
  color: #ff4444;
  font-size: 12px;
  margin-top: 4px;
}

.date-input.invalid {
  border-color: #ffa500;
  background-color: #fff8e6;
}

.date-input.invalid:focus {
  border-color: #ffa500;
  box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.2);
}

.date-input.readonly {
  background-color: #f5f5f5;
  cursor: pointer;
  color: #666;
}

.date-input.readonly:hover {
  background-color: #e9e9e9;
}

.reset-date-button {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 0 8px;
  font-size: 16px;
  transition: color 0.2s;
}

.reset-date-button:hover {
  color: #ff4444;
}

.date-input-container {
  display: flex;
  align-items: center;
  gap: 8px;
}