.table-container {
  width: 100%;
  padding: 20px;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.data-table th,
.data-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.data-table th:nth-child(2),
.data-table td:nth-child(2) {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
}

.data-table th:nth-child(3),
.data-table td:nth-child(3) {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}

.data-table th {
  background-color: #f5f5f5;
  font-weight: 600;
  color: #333;
}

.data-table tr:hover {
  background-color: #f9f9f9;
}

.period-input-container {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
}

.period-select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  min-width: 200px;
}

.date-input-container {
  display: flex;
  gap: 8px;
  align-items: center;
  
}

.date-input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 120px;
}

.date-input.invalid {
  border-color: #ffa500;
  background-color: #fff8e6;
}

.date-input.invalid:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.2);
}

.select-date-button {
  padding: 8px 12px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.select-date-button:hover {
  background-color: #45a049;
}

.amount-input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
}

.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inflation-checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.description-input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 200px;
}

.delete-button {
  padding: 8px 12px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.delete-button:hover {
  background-color: #da190b;
}

.add-row-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #2196F3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.add-row-button:hover {
  background-color: #1976D2;
} 