.table-settings {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.settings-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.settings-group > * {
  flex-shrink: 0;
}

.settings-group:not(:last-child) {
  margin-right: 10px;
}

.settings-group label {
  min-width: 100px;
  color: #333;
}

.inflation-input {
  width: 80px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.date-input-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.date-input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 120px;
}

.select-date-button {
  padding: 8px 12px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.select-date-button:hover {
  background-color: #45a049;
}

.reset-date-button {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 0 8px;
  font-size: 16px;
  transition: color 0.2s;
}

.reset-date-button:hover {
  color: #ff4444;
} 